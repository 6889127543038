$col-spec-bg: #67ad2f;
$body-bg: #eee;

/**
 * Navigation Module
 */
$navigation-arrow-color: #67ad2f;
$navigation-arrow-height: 90px !default;
$navigation-arrow-width: 55px !default;

/**
 * New Vals
 */
$ci-color: #67ad2f;
$col-spec-bg: #004c1c;
$navigation-arrow-color: #004c1c;

/**
 * Stage Colors
 * @type {[type]}
 */
$color-stage-polyline-normal: #004c1c;
$color-stage-polyline-hover: #67ad2f;
$color-stage-polyline-active: #67ad2f;
