@use 'sass:math';

/**
 * Basic Setup for the Styles Loaded by WebPack.
 * This File is prepended before each require, so use with care.
 */

@import '../../../vars';
@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins';

$icon-w: 30px;
$icon-h: 30px;
$overlay-w: 64px;
$overlay-h: 44px;
$overlay-b: 2px;

.marker-stage {

    .marker-icon {
        background: #f1f1f1;
        background: linear-gradient(-45deg, #fff 0, #f1f1f1 40%, #e1e1e1 68%, #e1e1e1 68%, #f6f6f6 100%);
        border-radius: 100%;
        box-shadow: 0 0 10px 0 rgb(0 0 0 / .8);
        box-sizing: border-box;
        color: #515149;
        font-weight: 700;
        height: $icon-h;
        line-height: $icon-h;
        position: relative;
        text-align: center;
        width: $icon-w;
        z-index: 1000;
    }

    .marker-mini-overlay {
        background-color: #fff;
        border: 2px solid #faf4f4;
        border-radius: 3px;
        box-shadow: 2px 2px 4px rgb(50 50 50 / .5);
        height: $overlay-h;
        left: math.div($overlay-w, -2) - math.div($icon-w, -2);
        margin-top: -3px;
        overflow: hidden;
        position: absolute;
        top: $icon-h;

        transition-duration: .1s;
        transition-property: width, height;
        transition-timing-function: ease-in-out;

        width: $overlay-w;

        &.right {
            left: auto;
            right: $icon-w - ( math.div($overlay-w, 2) - math.div($icon-w, 2) );
        }

        .preview {
            height: 100%;
            pointer-events: none;

            width: 100%;

            img {
                height: 100%;

                object-fit: cover;
                width: 100%;
            }
        }

        .slider-container {

            display: none;
            height: 100%;
            left: 0;

            padding-bottom: 30px;

            position: absolute;
            top: 0;
            width: 100%;

            figcaption,
            .carousel-control-prev,
            .carousel-control-next {
                display: none;
            }
        }

        .marker-overlay-title {
            bottom: 0;
            display: none;
            font-size: .875rem;
            height: 30px;
            line-height: 32px;
            overflow: hidden;
            padding-left: 10px;
            padding-right: 50px;
            position: absolute;
            width: 100%;
        }

        .fullscreen {
            align-items: center;
            display: flex;
            font-size: 1rem;
            height: 32px;
            justify-content: flex-end;
            line-height: 32px;
            padding: 0 10px;
            position: absolute;
            right: 0;
            top: 0;
            width: 50px;
        }
    }

    &.open {

        .marker-mini-overlay {
            height: 354px;
            padding-bottom: 30px;
            width: 484px;

            .slider-container,
            .marker-overlay-title {
                display: block;
            }

            .carousel-control-prev,
            .carousel-control-next {
                display: flex;
            }
        }
    }

    &.loaded {
        .marker-mini-overlay {
            .slider-container {
                display: block;
            }
        }
    }

    &.marker-stage--first,
    &.marker-stage--last {
        .marker-mini-overlay {
            margin-left: 9px;
            margin-top: 24px;
        }
    }

    .tao-marker {
        position: relative;

        .pin {
            color: #f1f1f1;
            height: 100%;
            width: 100%;

            svg {
                filter: drop-shadow(0 0 10px rgb(0 0 0 / .8));
                height: 100%;
            }
        }

        .flag {
            align-items: center;
            color: $navigation-arrow-color;
            display: flex;
            font-size: 22px;
            height: 50px;
            justify-content: center;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }

        &:hover {
            .flag {
                color: $col-spec-bg;
            }
        }
    }
}
