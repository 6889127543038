.tao-module--navigation {

    .arrows {

        color: $navigation-arrow-color;
        height: $navigation-arrow-height;
        left: -$navigation-arrow-width;
        line-height: $navigation-arrow-height;
        position: absolute;
        right: -$navigation-arrow-width;
        top: 0;
        user-select: none;
    }

    .arrow {
        background-color: #f5f5f5;
        cursor: pointer;
        height: 100%;

        line-height: inherit;
        position: absolute;
        text-align: center;
        top: 0;
        width: $navigation-arrow-width;

        .icon {
            align-items: center;
            display: flex;

            font-size: 2rem;
            height: 100%;
            justify-content: center;

            svg {
                stroke-width: 4px;
            }
        }

        /*
        &::before {
            color: $navigation-arrow-color;
            content: '';
            font-family: icomoon;
            font-size: 32px;
        }
*/

        &::after {
            box-shadow: 0 0 15px rgb(0 0 0 / .4);

            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }

        &.prev {
            left: 0;

            /*
            &::before {
                content: '\f053';
            }
            */
        }

        &.next {
            right: 0;

            /*
            &::before {
                content: '\f054';
            }
            */
        }

        span {

            color: $white;
            display: none;
            font-size: 14px;
        }
    }

    @include media-breakpoint-down( sm ) {

        .arrows {
            background-color: $navigation-arrow-color;
            color: white;
            height: 40px;

            left: 0;
            line-height: 40px;
            position: relative;
            right: auto;

            width: 100%;
        }

        .arrow {

            align-items: center;
            background-color: transparent;
            box-shadow: none;

            display: flex;
            flex-flow: row nowrap;

            height: 100%;
            text-align: start;
            width: 50%;

            .icon {
                font-size: 1rem;
                height: auto;
                margin: 0 5px;
            }

            span {
                display: inline;
            }

            &::after {
                content: none;
            }

            &.next {
                flex-direction: row-reverse;
            }
        }
    }
}
