/**
 * Basic Setup for the Styles Loaded by WebPack.
 * This File is prepended before each require, so use with care.
 */

@import '../../../vars';
@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins';

.tao-module--fullscreen.leaflet-control {
    background-color: var(--bs-dark, #000);

    display: none;
    height: 100vh;
    left: 0;
    margin: 0;

    top: 0;
    width: 100vw;

    z-index: 20000;

    &.open {

        display: block;
    }

    .icon-close {
        align-items: center;
        color: white;
        cursor: pointer;
        display: flex;

        font-size: 24px;
        height: 50px;
        justify-content: center;
        line-height: 50px;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        width: 50px;
        z-index: 20000;
    }

    .carousel {
        max-height: 100%;
        min-width: 100%;

        img {
            max-height: 100vh;
            object-fit: contain;
        }
    }
}

.leaflet-left.leaflet-top {
    z-index: 200000;
}
