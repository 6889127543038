// Here be content

.tao-module--navigation {

    .overlay_content.has-tabs {
        padding-bottom: 52px;
    }

    img {
        max-width: 100%;
    }

    .container {
        height: 100%;
        overflow: auto;

        scrollbar-color: #c1c1c1 #fff;

        @media (prefers-reduced-motion: no-preference) {
            scroll-behavior: smooth;
        }

        &::-webkit-scrollbar {
            width: 16px; /* Mostly for vertical scrollbars */
        }

        &::-webkit-scrollbar-thumb { /* Foreground */
            background: #c1c1c1;
            background-clip: padding-box;
            border: 4px solid transparent;
            border-radius: 8px;
        }

        &::-webkit-scrollbar-track { /* Background */
            background: #eee;
        }
    }

    .poi-headline {
        align-items: center;
        background-color: $col-spec-bg;
        color: #fff;

        display: flex;
        flex-direction: row nowrap;
        margin: 0;
        min-height: 45px;
        padding-top: 3px;
        position: relative;
        width: 100%;

        .poi-icon {
            background-color: #fff;
            border: 3px solid #fff;
            border-radius: 100%;
            height: 26px;
            margin: 0 10px;
            width: 26px;
        }

        .poi-title {
            display: block;
            flex: 1;
            font-size: 18px;
            line-height: 20px;
            overflow: hidden;
            padding-right: 10px;
            width: 100%;
        }
    }

    .scrollable {
        margin: 2rem 0;
    }

    .tabs {
        background-color: #f5f5f5;
        bottom: 0;

        box-shadow: 0 0 8px rgb(0 0 0 / .38);

        display: flex;
        flex-flow: row nowrap;
        height: 52px;
        left: 0;
        list-style: none;
        margin: 0;
        padding: 0;
        position: absolute;
        width: 100%;
        z-index: 50;

        li {
            color: #62625a;
            cursor: pointer;
            flex: 0 1 25%;
            font-size: 12px;
            height: 52px;
            line-height: 52px;
            text-align: center;

            &.active,
            &:hover {
                background-color: #e6e6e6;
            }

            a {
                color: inherit;
                display: block;
                height: 100%;
                text-decoration: none;
                width: 100%;
            }
        }
    }
}
