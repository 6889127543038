.tao-module--navigation.leaflet-control {
    background-color: white;
    display: flex;
    flex-flow: column nowrap;
    height: auto;

    margin-right: 20px;
    margin-top: 20px;
    position: absolute;
    top: 36px;

    width: 428px;

    .leaflet-left & {
        left: 43px;
    }

    .leaflet-right & {
        right: 43px;
    }

    .overlay-content {

        height: 0;

        max-height: calc(100vh - 90px - 36px - 10px);

        overflow: hidden;
        transition: height 500ms ease;
    }

    &.open .overlay-content {
        height: 600px;
    }

    @include media-breakpoint-down( sm ) {

        height: 100vh;
        margin-right: 0;
        margin-top: 0;
        max-width: 428px;
        padding-top: 0;
        top: 0;
        width: 0;

        .leaflet-left & {
            left: 0;
        }

        .leaflet-right & {
            right: 0;
        }

        &.open {
            width: 90vw;
        }

        .content {
            flex: 1;
            left: 0;
            max-height: none;
            position: relative;
            top: 0;
            transition: none;

            &.overlay-content,
            .overlay-inner-content {
                height: 100% !important;
            }
        }
    }
}
