@use 'sass:color';

/**
 * Basic Setup for the Styles Loaded by WebPack.
 * This File is prepended before each require, so use with care.
 */

@import '../../../vars';
@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins';

.stage-popup {

    cursor: pointer;

    &.leaflet-popup {
        height: 0 !important;
        width: 0 !important;
    }

    .leaflet-popup-content {
        margin: 0;
        margin-top: -20px;
        padding: 0;
        position: relative;
        z-index: 700;
    }

    .wrapper {
        display: flex;
        flex-flow: row wrap;

        height: 0;
        width: 0;
    }

    h1 {
        font-size: 2em;
        text-transform: uppercase;
    }

    h2 {
        font-size: 1.5em;
        text-transform: uppercase;
    }

    h3 {
        font-size: 1.25em;
        text-transform: none;
    }

    h1,
    h2,
    h3 {

        background-color: $white;
        box-shadow: 5px 8px 6px rgb(50 50 50 / .41);

        height: 1.5em;
        line-height: 1.5em;

        margin: 0;
        padding: 0 .5rem;
        position: relative;
        white-space: nowrap;
    }

    .specifications {

        background-color: color.adjust($col-spec-bg, $alpha: -.1);
        color: $white;
        font-size: 1.125em;
        height: 2.5em;

        line-height: 2.5em;

        padding: 0 .5rem;
        position: relative;
        white-space: nowrap;
        z-index: 90;

        span {
            padding: 0 .5rem;

            .icon {
                font-size: 1.25em;
            }
        }
    }

    .leaflet-popup-tip-container {
        height: 42px;
        left: auto;
        margin-right: -5px;

        position: absolute;
        right: 100%;
        top: 0;
        width: 25px;
    }

    .leaflet-popup-tip {
        background-color: transparent;
        background-image: url('https://www.weitwanderwege.com/wp-content/plugins/tao-map/img/weg/etappen_hover_con3.png');
        background-repeat: no-repeat;
        background-size: 100%;

        border: 0;

        cursor: pointer;
        display: block;
        height: 42px;
        margin: 0;
        padding: 0;

        position: absolute;
        transform: none;
        width: 25px;
    }
}
