.tao-module--navigation {

    &.open {
        .overlay_toggler {
            transform: rotate(-180deg);
        }
    }

    .stage {

        background-color: #fff;

        box-shadow: 0 0 15px rgb(0 0 0 / .4);
        display: flex;
        flex-flow: column wrap;
        height: auto;
        min-height: 90px;
        overflow: hidden;
        padding-left: 10px;
        padding-right: 10px;
        position: relative;
        width: 100%;

        z-index: 200;

        .headline {
            color: #515149;

            display: none;
            font-family: $default-font;
            font-size: 20px;
            font-weight: 300;
            line-height: 24px;
            margin-top: 12px;
            max-height: 48px;
            max-width: 305px;
            overflow: hidden;
            text-transform: none;
        }

        .infos {
            display: flex;
            flex-flow: row nowrap;
        }

        .specifications {
            align-items: center;
            display: flex;
            flex: 1;
            justify-content: flex-start;

            > * {
                height: 39px;
                line-height: 39px;
            }
        }
    }

    .closer {
        align-items: center;
        align-self: flex-start;
        cursor: pointer;
        display: flex;
        font-weight: bold;
        height: 50px;
        justify-content: center;
        line-height: 50px;
        text-align: center;
        width: 46px;

        .icon-close {
            height: 14px;

            width: 12px;
        }
    }

    .overlay_minimizer,
    .overlay_toggler {
        color: #aaa;

        cursor: pointer;
        display: block;

        font-size: 21px;
        height: 28px;
        line-height: 28px;
        padding: 0;
        position: absolute;
        right: 3px;
        text-align: center;
        top: 2px;
        width: 28px;

        &.closed {
            background-image: url('close-btn.png');
            background-position: center;
            background-size: 12px;
        }
    }

    .overlay_toggler {
        align-items: center;
        background-color: white;

        display: flex;
        height: 40px;
        justify-content: center;
        right: 100%;
        top: 20px;
        width: 40px;
        z-index: 1000;
    }

    .counter {
        color: $navigation-arrow-color;
        display: inline-block;

        font-size: 12px;
        line-height: 17px;
        margin-left: -5px;
        margin-top: 10px;
        min-width: 70px;
        padding-top: 6px;
        text-align: center;

        .icon-sign {

            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            margin-right: 5px;

            margin-top: -1px;
        }

        .number {
            font-size: 20px;
        }
    }

    .directions {
        color: var(--bs-secondary);
        font-size: 12px;
        line-height: 21px;
        margin-top: 7px;

        padding-left: 12px;

        position: relative;

        span {
            font-size: 14px;
            font-weight: 500;

            &::before {
                background: $navigation-arrow-color;
                border-radius: 100%;
                content: '';
                height: 7px;
                left: 0;
                margin-top: 7px;
                position: absolute;
                width: 7px;
            }
        }

        &::before {
            background: $navigation-arrow-color;
            content: '';
            height: 60%;
            left: 3px;
            position: absolute;
            top: 20%;
            width: 1px;
        }
    }

    .specifications {

        color: $navigation-arrow-color;
        font-size: 14px;
        letter-spacing: -.02em;

        position: relative;
        white-space: nowrap;
        z-index: 90;

        span {
            padding: 0 .5rem;

            .icon {
                font-size: 1.25em;
            }
        }
    }
}
