@use 'sass:color';

/**
 * Basic Setup for the Styles Loaded by WebPack.
 * This File is prepended before each require, so use with care.
 */

@import '../../../vars';
@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins';

.poi_holder {
    height: 100%;
    overflow: visible;
    position: relative;
    width: 100%;

    &.active {

        .poi_arrow {
            display: block;
        }
    }

    &:hover,
    &.active {
        .poi_blue_line {
            width: auto;

            &::before {
                display: block;
            }

            .poi_popup {
                width: 328px;
            }
        }
    }

    .poi_blue_line {
        align-items: stretch;
        display: flex;
        flex-flow: row nowrap;
        height: auto;
        left: 50%;
        overflow: hidden;
        position: absolute;
        top: 50%;
        transform: translate3d(0, -50%, 0);
        width: 0;

        &::before {

            background-color: color.adjust($col-spec-bg, $alpha: -.2);
            clip-path: polygon(0 50%, 100% 100%, 100% 0);
            content: '';
            display: none;
            overflow: hidden;
            position: relative;
            width: 52px;
            z-index: 90;
        }

        .poi_popup {
            background-color: color.adjust($col-spec-bg, $alpha: -.2);
            color: #fff;
            display: block;
            font-size: 18px;
            height: 36px;
            line-height: 26px;
            overflow: hidden;
            padding: 5px 10px;
            position: relative;
            transition: width .3s ease-in-out;
            width: 0;
            z-index: 90;
        }
    }
}

.leaflet-marker-icon {
    &.poi {
        &.active {
            z-index: 20000 !important;
        }
    }
}

.poi_marker {
    background-repeat: no-repeat;
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 100;
}

.poi_arrow {
    background: url('../../img/weg/detail-arrow.png') no-repeat;
    background-position: 0 5px;
    display: none;
    height: 33px;
    left: 1px;
    padding-top: 3px;
    position: absolute;
    top: 20px;
    width: 35px;
}

.mehr-erfahren-btn {
    color: #1b74a5;
    float: left;
    font-size: 6.5pt;
    margin-left: -138px;
    text-align: center;
    width: 130px;
}

.mehr-b-btn {
    color: #333;
    float: left;
    font-size: 12px;
    margin-bottom: -25px;
    text-transform: uppercase;
    width: 100%;
}
