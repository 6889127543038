/**
 * Basic Setup for the Styles Loaded by WebPack.
 * This File is prepended before each require, so use with care.
 */

@import '../../../vars';
@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins';

.label-box {
    background: #fff;
    color: var(--bs-secondary) !important;
    cursor: pointer;
    display: block;
    font-size: 20px;
    font-weight: 300;
    height: auto !important;
    line-height: 22px;
    padding: 3px 8px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    width: auto !important;

    &.small-text {
        background: none;
        cursor: default;
        font-size: 13px;
        font-weight: 500;
        line-height: 15px;
        padding: 2px 8px;
        text-transform: none;

        img,
        img.img-top {
            top: -25px;
        }

        img.img-bottom {
            bottom: -25px;
            top: inherit;
        }

        img.img-left,
        img.img-right {
            margin-top: -11px;
            top: 50%;
        }
    }

    img {
        height: 22px !important;
        left: 50%;
        margin-left: -30px;
        position: absolute;
        top: -30px;
        width: 35px !important;

        &.img-top {
            left: 50%;
            margin-left: -30px;
            top: -30px;
        }

        &.img-bottom {
            bottom: -30px;
            left: 50%;
            margin-left: -30px;
            top: inherit;
        }

        &.img-left {
            left: 0;
            margin-left: -42px;
            margin-top: -11px;
            top: 50%;
        }

        &.img-right {
            left: 100%;
            margin-left: 0;
            margin-top: -11px;
            top: 50%;
        }
    }
}

.poi_logo_holder {
    display: block;
    height: 25px;
    position: relative;
    width: 25px;
}
