/**
 * Basic Setup for the Styles Loaded by WebPack.
 * This File is prepended before each require, so use with care.
 */

@import '../../../vars';
@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins';

$spec-color: #3c6373 !default;
$default-font: 'Helvetica Neue',helvetica,arial !default;
$nav-mobile-bp: sm;

@import 'scss/control';
@import 'scss/stage';
@import 'scss/arrows';
@import 'scss/content';
