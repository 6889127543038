@use 'sass:color';

/**
 * Basic Setup for the Styles Loaded by WebPack.
 * This File is prepended before each require, so use with care.
 */

@import '../../../vars';
@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins';

.tao-module--title {

    .wrapper {
        display: flex;
        flex-flow: row wrap;

        height: 0;
        width: 0;
    }

    h1 {
        font-size: 2em;
    }

    h2 {
        font-size: 1.5em;
    }

    h1,
    h2 {

        background-color: $white;
        box-shadow: 5px 8px 6px rgb(50 50 50 / .41);

        height: 1.5em;
        line-height: 1.5em;

        margin: 0;
        padding: 0 .5rem;
        position: relative;
        text-transform: uppercase;
        white-space: nowrap;
    }

    .specifications {

        background-color: color.adjust($col-spec-bg, $alpha: -.1);
        color: $white;
        font-size: 1.125em;
        height: 2.5em;

        line-height: 2.5em;

        padding: 0 .5rem;
        position: relative;
        white-space: nowrap;
        z-index: 90;

        span {
            padding: 0 .5rem;

            .icon {
                font-size: 1.25em;
            }
        }
    }
}
