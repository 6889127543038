/**
 * Basic Setup for the Styles Loaded by WebPack.
 * This File is prepended before each require, so use with care.
 */

@import 'vars';
@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/mixins';

@import 'setup';
@import 'bootstrap/scss/bootstrap-reboot';
@import 'bootstrap/scss/bootstrap-grid';
@import 'bootstrap/scss/bootstrap-utilities';
@import 'bootstrap/scss/carousel';

.feather,
.icomoon {
    height: 1em;
}

.leaflet-map-pane svg {

    &.feather,
    &.icomoon {
        left: auto;
        position: relative;
        top: auto;
    }
}

.carousel,
.carousel-inner {
    outline: 0;
}

.carousel-item figcaption {

    background: rgb(0 0 0 / .4);
    bottom: 0;
    color: white;
    padding: 5px;
    text-align: center;
}

body.loading {

    #tao-map {
        visibility: hidden;
    }
}

.loading-spinner {
    display: none;
    height: 80px;
    left: 50%;

    position: absolute;
    top: 50%;

    transform: translate3d(-50%, -50%, 0);
    width: 80px;

    .loading & {
        display: inline-block;
    }

    &::before {
        animation: lds-dual-ring 1.2s linear infinite;
        border: 6px solid $ci-color;
        border-color: $ci-color transparent;
        border-radius: 50%;
        content: ' ';
        display: block;
        height: 64px;
        margin: 8px;
    }

    &::after {
        color: $ci-color;
        content: 'Loading';
        display: inline-block;
        text-align: center;
        width: 100%;
    }
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
