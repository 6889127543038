/**
 * Basic Setup for the Styles Loaded by WebPack.
 * This File is prepended before each require, so use with care.
 */

@import '../../../vars';
@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins';

.tao-module--error {

    background-color: white;

    color: black;
    height: 100vh;
    left: 0;

    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 99999999;
}
