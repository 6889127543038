/**
 * Basic Setup for the Styles Loaded by WebPack.
 * This File is prepended before each require, so use with care.
 */

@import '../../../vars';
@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins';

// Colors
$color-stage-polyline-normal: #e9c400 !default;
$color-stage-polyline-hover: $color-stage-polyline-normal !default;
$color-stage-polyline-active: #154981 !default;
$color-stage-polyline-inactive: #aaa !default;

// Weight
$weight-stage-polyline-normal: 5 !default;
$weight-stage-polyline-hover: 9 !default;
$weight-stage-polyline-active: $weight-stage-polyline-hover !default;
$weight-stage-polyline-inactive: $weight-stage-polyline-normal !default;

:root {
    --tao-stage-color-normal: #{$color-stage-polyline-normal};
    --tao-stage-color-hover: #{$color-stage-polyline-hover};
    --tao-stage-color-active: #{$color-stage-polyline-active};
    --tao-stage-color-inactive: #{$color-stage-polyline-inactive};
    --tao-stage-weight-normal: #{$weight-stage-polyline-normal};
    --tao-stage-weight-hover: #{$weight-stage-polyline-hover};
    --tao-stage-weight-active: #{$weight-stage-polyline-active};
    --tao-stage-weight-inactive: #{$weight-stage-polyline-inactive};
}

/*
.leaflet-overlay-pane .stage {

    stroke: $color-stage-polyline-normal;
    stroke-width: $weight-stage-polyline-normal;

    &.hover {
        stroke: $color-stage-polyline-hover;
        stroke-width: $weight-stage-polyline-hover;
    }

    &.active {
        stroke: $color-stage-polyline-active;
        stroke-width: $weight-stage-polyline-active;
    }

    &.dist {
        stroke: $color-stage-polyline-inactive;
    }

    &.inactive {

        stroke: $color-stage-polyline-inactive;
        stroke-width: $weight-stage-polyline-normal;
    }
}
*/
