/**
 * Basic Setup for the Styles Loaded by WebPack.
 * This File is prepended before each require, so use with care.
 */

@import '../../../vars';
@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins';

.tao-module--zoom {
    background-color: #fff;
    font-size: 18px;
    height: auto;

    &.leaflet-control {
        margin: 0;

        margin-top: -275px;
    }

    .leaflet-left & {
        left: 0;
    }

    .leaflet-bottom & {
        bottom: 0;
    }

    .inner {
        height: auto;
        margin: 0 auto;
        width: 52px;
    }

    .icon-plus,
    .icon-minus {
        border-bottom: 1px solid #ccc;
    }

    .icon-plus,
    .icon-minus,
    .icon-center {
        align-items: center;
        color: #ccc;
        display: flex;
        height: 49px;
        justify-content: center;
        line-height: 49px;
        text-align: center;
        width: 100%;

        &:hover {
            color: $navigation-arrow-color;
        }
    }
}
